import React, { useState, useRef } from 'react'
import Lottie from 'lottie-web';
// import animation1 from '../animations/nivo1_bg.json';
import animation1 from '../animations/infoScr.json';
// import animation2 from '../animations/nivo2_bg.json';
// import animation3 from '../animations/nivo3_bg.json';
import aniamtionBtn from '../animations/zavrsi_igru.json';
import ChestClass from './kochezi/ChestClass';
//import { Chest } from './kochezi/Chest';


export const Level = ({currentSession, bonusMasterOpen, bonusMasterTransfer, currentLevel, updatePoints , handleNextLevel, levelPrizes, isLoading}) => {
    const animationContainer = React.createRef();
    const endGame_btn = React.createRef();
    const nextLevel_btn = React.createRef();
    const [isChestOpen, setIsChestOpen] = useState(false);
    const [numOfOpenedChest, setNumberOfOpenedChests] = useState(0);
    const [bonusPoints1, setBonusPoints1] = useState(null);
    const [bonusPoints2, setBonusPoints2] = useState(null);
    const [bonusPoints3, setBonusPoints3] = useState(null);
    const [bonusPoints4, setBonusPoints4] = useState(null);
    const [bonusPoints5, setBonusPoints5] = useState(null);
    const [totalPoints, setTotalPoints]   = useState(0);
    const [openedChests, setOpenedChests] = useState(0);
    const [playChestAnimation, setPlayChestAnimation] = useState(false);
    let  currentAnimation=null;

    //const updateLevelBG = () => {   
      let audioUrl = './sfx/level_1/Fishing_Boat_at_Sea_v4.mp3';

      switch(currentLevel) {
        case 1:
          currentAnimation = animation1;
          audioUrl = "./sfx/level_1/Fishing_Boat_at_Sea_v4.mp3"
          break;
        // case 2:
        //   currentAnimation = animation2;
        //   audioUrl = "./sfx/level_2/sea-waves v4.mp3"
        //   break;
        // case 3:
        //   currentAnimation = animation3;
        //   audioUrl = "./sfx/level_3/Jungle Sounds v4.mp3"
        //   break;       
        default:     
          currentAnimation = animation1;
          audioUrl = "./sfx/level_1/Fishing_Boat_at_Sea_v4.mp3"
      }
      
    //levelAudio.current = new Audio(audioUrl);
    
    // const [levelAudio, setLevelAudio] = useState(new Audio(audioUrl));
    const [nextAudio, setNextAudio] = useState(new Audio("./sfx/nextLevel/Positive Game Win v2.mp3"));
    const [ myAnimationData, setMyAnimationData ]= useState(currentAnimation);
    let bgAnim;
    // console.log('levelPrizes:', levelPrizes );

    React.useEffect(() => {
      //const anim = updateLevelBG();
      // console.log("useeffect level");
        // const bgAnim = Lottie.loadAnimation({
        //   container: animationContainer.current,
        //   animationData: myAnimationData
        // });
        // const mainAnim = Lottie.loadAnimation({
        //   container: animContainer.current,
        //   animationData: endAnimationData,
        //   autoplay: true,
        //   loop: false
        // });
        bgAnim = Lottie.loadAnimation({
          container: animationContainer.current,
          animationData: myAnimationData
        })
        console.log(bgAnim);
        
        // bgAnim.setSpeed(1);
        // console.log(bgAnim);
        // levelAudio.loop = true;
        // levelAudio.play();

        if (currentSession.gameState !== null && currentSession.gameState.opened.length > 0) {
          // const chestsOpenedPerLevel = 3 - currentSession.gameState.opened.length / currentLevel;
          const chestsOpenedPerLevel = 5 - ((currentLevel * 5) - currentSession.gameState.opened.length);
          if (chestsOpenedPerLevel > 0) {
            for (let i=1; i < chestsOpenedPerLevel+1; i++) {
              // console.log("openedChests", (currentLevel-1) * 3 + i - 1);
              switch (i) {
                case 1:
                  setBonusPoints1(currentSession.gameState.opened[(currentLevel-1) * 5 + i - 1]);
                  break;
                // case 2:
                //   setBonusPoints2(currentSession.gameState.opened[(currentLevel-1) * 5 + i - 1]);
                //   break;
                // case 3:
                //   setBonusPoints3(currentSession.gameState.opened[(currentLevel-1) * 5 + i - 1]);
                //   break;
                default:
              }
              setOpenedChests(chestsOpenedPerLevel);
            }
          }
        }

        return () => { // --> componentWillUnmount
          // levelAudio.pause();
          //levelAudio.release();
          // console.log('componentWillUnmount');
          //levelAudio.stop();
          //levelAudio.release();
        };
      }, [])

      React.useEffect(() => {
        if (openedChests > 0) {
          setNumberOfOpenedChests(numOfOpenedChest+1);
          setIsChestOpen(true);
        }
      }, [openedChests])


      React.useEffect(() => {
        const endGameAnimbtn = Lottie.loadAnimation({
          container: endGame_btn.current,
          animationData: aniamtionBtn,
          loop: true
        });
      }, [isChestOpen])

      // React.useEffect(() => {
      //   const nextBtn = Lottie.loadAnimation({
      //     container: nextLevel_btn.current,
      //     animationData: aniamtionBtnNext,
      //     loop: true
      //   });
      // }, [isChestOpen])

      React.useEffect(() => {          
        isLoading(false);
      });
      
      const handleBtnClick = (e) => {
        e.preventDefault();
        nextAudio.play();
        if (currentLevel === 3) {
          bonusMasterTransfer();
        }
        handleNextLevel();
      }
      const chestClicked = async (chestID) => {
        bgAnim.loop = false;
        try {
          const sessionData = await bonusMasterOpen({
            session: currentSession.id,
            chest: chestID.substring(1),
          });
          const _points = (typeof(sessionData) == 'object') ? sessionData.gameState.opened : [1,1,1,1,1];
          //console.log("sessionData:",sessionData);
          // updatePoints(_points, _totalPoints);
          //console.log("updatedPoints:" ,_points);
          //setPlayChestAnimation(true);
          let _numOfOpenedChest = numOfOpenedChest;
          setTotalPoints(sessionData.gameState.totalPoints);
          switch (chestID) {
            case "k1":
              setBonusPoints1(_points[0]);
              break;
            case "k2":
              setBonusPoints2(_points[1]);
              break;
            case "k3":
              setBonusPoints3(_points[2]);
              break;
            case "k4":
              setBonusPoints4(_points[3]);
              break;
            case "k5":
              setBonusPoints5(_points[4]);
              break;
            default:
          }
          setOpenedChests(openedChests+1);
          const timer = setTimeout(() => {
            // console.log('This will run after 1 second!');
            setBonusPoints1(_points[0]);
            setBonusPoints2(_points[1]);
            setBonusPoints3(_points[2]);
            setBonusPoints4(_points[3]);
            setBonusPoints5(_points[4]);
            // audioWelcome.play();
          }, 1500);

          const timer2 = setTimeout(() => {
            // console.log('This will run after 1 second!');
            handleNextLevel();
            // audioWelcome.play();
          }, 4500);

          return sessionData;
        } catch (e) {
          console.log(e);
        }
      }
      const getNumberOfOpenedChest = () => {
        return 
      }
    return (        
        <div className="level-animation-container" ref={animationContainer}>
          <div className='chests'>            
            <div className='chests-container'>
              <ChestClass key='1dsa'  playChest={playChestAnimation} customClass="k1" bonusPoints={bonusPoints1} totalPoints={totalPoints} onClickEl={ (e) => { chestClicked(e)}} />
              <ChestClass key='1dsad' playChest={playChestAnimation} customClass="k2" bonusPoints={bonusPoints2} totalPoints={totalPoints} onClickEl={ (e) => { chestClicked(e)}} />
              <ChestClass key='1dsac' playChest={playChestAnimation} customClass="k3" bonusPoints={bonusPoints3} totalPoints={totalPoints} onClickEl={ (e) => { chestClicked(e)}} />  
              <ChestClass key='1dsaa' playChest={playChestAnimation} customClass="k4" bonusPoints={bonusPoints4} totalPoints={totalPoints} onClickEl={ (e) => { chestClicked(e)}} />  
              <ChestClass key='1dsab' playChest={playChestAnimation} customClass="k5" bonusPoints={bonusPoints5} totalPoints={totalPoints} onClickEl={ (e) => { chestClicked(e)}} />  
            </div>
          </div>
          {/* {isChestOpen && currentLevel < 1  &&  <button className='btn button_next' onClick={handleBtnClick} ><div className='next_btn' ref={nextLevel_btn}></div></button>}            */}
          {isChestOpen && currentLevel === 1  &&  <button className='btn button_getbonus_container' onClick={handleBtnClick} ref={endGame_btn} ></button>}           
        </div>
    )
}
