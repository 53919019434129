import React, { useEffect, useRef, Component, useState} from 'react';
import Lottie from 'lottie-web';
import { Finger } from './Finger';
// import fullChestAnim from '../../animations/clickedflower1.json';
// import emptyChestAnim from '../../animations/clickedflower1.json';
import clickedFlower1 from '../../animations/cvekje_01_click.json';
import clickedFlower2 from '../../animations/cvekje_02_click.json';
import clickedFlower3 from '../../animations/cvekje_03_click.json';
import clickedFlower4 from '../../animations/cvekje_04_click.json';
import clickedFlower5 from '../../animations/cvekje_05_click.json';
import openFlower1 from '../../animations/cvekje_01_no_click.json';
import openFlower2 from '../../animations/cvekje_02_no_click.json';
import openFlower3 from '../../animations/cvekje_03_no_click.json';
import openFlower4 from '../../animations/cvekje_04_no_click.json';
import openFlower5 from '../../animations/cvekje_05_no_click.json';


export default class ChestClass extends Component {
  constructor(props) {
    super(props);
    this.points = 0;
    this.totalPoints = 0;
    this.audioBtn = new Audio("./sfx/chest_full/Game Win 2 v2.mp3")
    this.audioChestEmpty = new Audio("./sfx/chest_empty/Game Win v2.mp3")
    this.animationContainer = React.createRef();
    this.animationContainerEmpty = React.createRef();
    this.chestAnim = null;
    this.chestAnim1 = null;
    this.chestAnim2 = null;
    this.chestAnim3 = null;
    this.chestAnim4 = null;
    this.chestAnim5 = null;
    this.chestAnimEmpty = null;
    this.state = {
      myclassname: 'points hide',
      isChestOpen: false,
      currentActive: '',
      displayFinger: 'hide',
      isChestClicked: false,
      isChestFull: false
    }  
    //this.points = this.props.bonusPoints[this.props.numOpenedChest];
    switch (this.props.customClass) {
      case "k1":
        this.chestAnimation = clickedFlower1;
        this.chestAnimEmpty = openFlower1;
        break;
      case "k2":
        this.chestAnimation = clickedFlower2;
        this.chestAnimEmpty = openFlower2;
        break;
      case "k3":
        this.chestAnimation = clickedFlower3;
        this.chestAnimEmpty = openFlower3;
        break;
      case "k4":
        this.chestAnimation = clickedFlower4;
        this.chestAnimEmpty = openFlower4;
        break;
      case "k5":
        this.chestAnimation = clickedFlower5;
        this.chestAnimEmpty = openFlower5;
        break;
      default:
    }
    // this.chestAnimation = fullChestAnim;
  }

  componentDidMount() {
    // console.log("MOUNT", this.state.isChestClicked);
    
    const mainAnimEmpty = Lottie.loadAnimation({
      container: this.animationContainerEmpty.current,
      animationData: this.chestAnimEmpty,
      autoplay: false,
      loop: false
    }); 
    // console.log(this.props);
    const mainAnim = Lottie.loadAnimation({
      container: this.animationContainer.current,
      animationData:  this.chestAnimation,
      autoplay: false,
      loop: false
    }); 
    this.chestAnim = mainAnim;   
    this.chestAnimEmpty = mainAnimEmpty;

    const timer = setTimeout(() => {
      this.setState({displayFinger: ''});
    }, 3200);
    return () => clearTimeout(timer);
    //mainAnim = anim;
  }
  componentWillUnmount() {
    console.log("Bye");
  }
  
  componentDidUpdate(prevProps, prevState) {
    //console.log("componentDidUpdate: ", JSON.stringify(this.props.bonusPoints));
    // console.log("prevProps: ", prevProps.bonusPoints);
    // console.log("prevState: ", prevState);
    //console.log("prevState.currentActive", prevProps, prevState, this.props.playChest);
    if (this.props.bonusPoints !== prevProps.bonusPoints) {
      this.points = this.props.bonusPoints; //[this.props.numOpenedChest];
      this.totalPoints = this.props.totalPoints; //[this.props.numOpenedChest];
      this.updateAndPlayChest();
    }
    // if (prevState.currentActive === this.props.customClass && JSON.stringify(prevProps.bonusPoints) !== JSON.stringify(this.props.bonusPoints)) {
    //   console.log("comp update ", JSON.stringify(this.props.bonusPoints));
    //   //console.log("comp update ", this.props.bonusPoints[this.props.numOpenedChest]);
    //   //this.points = this.props.bonusPoints[this.props.numOpenedChest];
    //   this.updateAndPlayChest();
    //   this.setState({
    //     currentActive: ''
    //   });
    // }
  }

  // componentWillUpdate(prevProps, prevState) {
  //   console.log("prevProps & State: ", prevProps)
  //   console.log("prevProps & State: ", prevState);
  //   // this.points = this.props.bonusPoints[this.props.numOpenedChest];
  // }

  openChest() {
    //setMyClassName("points show");
    //(Number(this.props.bonusPoints) > 0) ? this.audioBtn.play() : this.audioChestEmpty.play();

    this.setState({
      myclassname: 'points show',
      isChestOpen: true,
      currentActive: this.props.customClass,
      displayFinger: 'hide',
      showFullChest: true
    });
  }

  updateAndPlayChest() {
    this.openChest();
    // console.log("updateAndPlayChest", this.points, this.totalPoints);
    if (this.points === this.totalPoints){
      this.chestAnim.play();
      this.setState({showFullChest: true});
      this.audioBtn.play();
      // this.state.myclassname = 'points show ';
      this.setState({myclassname: 'points show', displayFinger: 'hide'});
    } else {
      this.setState({showFullChest: false});
      this.chestAnimEmpty.play();
      // this.audioChestEmpty.play();
      this.setState({myclassname: 'points show negative'});
    }
  }
  async getSessionchestPoints() {
    // console.log("getSessionchestPoints");
    const getSessin = await this.props.onClickEl();
    // console.log("getSessin Chest", getSessin, this.points); 
    return getSessin;
  }
  handleClick(e) {
    e.preventDefault();
    // console.log('handleClick', this.state.isChestOpen);
    if (!this.state.isChestOpen) {
        // this.getSessionchestPoints().then(console.log("TESTING")) 
        this.props.onClickEl(this.props.customClass);
        //this.setState({displayFinger: "hide"});
        //this.openChest();
        //hideFinger("hide");
        //this.chestAnim.play();
        //mainAnim.play();
        
    }
  }
  render() {
    return (
      <div className='chest-wrapper'>
          <div className={"chest "+this.props.customClass}>
              <div className={'hitTarget '+!this.state.displayFinger} onClick={this.handleClick.bind(this)}></div>
              <div  id={this.props.customClass} className={'kovcheg-anim_full '+!!this.state.showFullChest} ref={this.animationContainer}></div>
              <div  id={'e-'+this.props.customClass} className={'kovcheg-anim_full '+!this.state.showFullChest} ref={this.animationContainerEmpty}></div>
              <div className={this.state.myclassname}>{this.points}<br/><span className="small-text"> RSD</span></div>   
              <div className={this.state.displayFinger}>
                <Finger />
              </div>
          </div>
      </div>
    );
  }
}
